<template>
  <v-item-group class="mt-4" mandatory v-model="activePhoto">
    <v-container fluid class="ma-0 pa-0">
      <v-row class="ma-0 pa-0" dense>
        <v-col
          cols="3"
          v-for="(photo, n) in photos"
          :key="n"
        >
          <v-item v-slot:default="{ active, toggle }">
            <v-card
              @click="toggle"
              style="cursor: pointer"
              :elevation="active ? 2 : undefined"
            >
              <v-img
                :class="{ 'elevation-1': active }"
                @click="toggle"
                aspect-ratio="1"
                :src="photo"
              >
                <template v-slot:placeholder>
                  <v-container fluid class="ma-0 pa-0 fill-height">
                    <v-row class="ma-0 pa-0" justify="center" align="center">
                      <v-col cols="12" class="text-center">
                        <v-progress-circular indeterminate />
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-img>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
export default {
  name: 'ImagePickerGrid',

  props: {
    value: {
      type: Number,
      default: () => 0
    },

    photos: {
      type: Array,
      required: true
    }
  },

  data () {
    return ({
      activePhoto: this.value
    })
  },

  watch: {
    activePhoto (v) {
      this.$emit('input', v)
    }
  }
}
</script>
