<template>
  <v-container
    fluid
    :class="{
      'ma-0': !isOnPC,
      'pa-0': !isOnPC,
      'px-12': isOnPC
    }"
  >
    <v-row justify="top" class="ma-0 pa-0 fill-height">
      <v-col
        :class="{
          'ma-0': !isOnPC,
          'pa-0': !isOnPC
        }"
        cols="12"
        md="4"
      >
        <v-img aspect-ratio="1" v-if="photos.length" :src="photos[activePhoto].publicUrl">
          <template v-slot:placeholder>
            <v-container fluid class="ma-0 pa-0 fill-height">
              <v-row class="ma-0 pa-0" justify="center" align="center">
                <v-col cols="12" class="text-center">
                  <v-progress-circular indeterminate />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-img>
        <image-picker :photos="gridPhotos" v-model="activePhoto" />
      </v-col>
      <v-col cols="12" md="8">
        <h1 class="display-3" v-text="item.name" />
        <h2 class="text--secondary font-weight-light">
          by <router-link class="text-decoration-none" :to="`/restaurants/${restaurantId}`">{{ restaurantName }}</router-link>
        </h2>
        <p class="my-2"><v-chip v-if="item.price" color="primary">{{ formattedPrice }}</v-chip></p>
        <p class="my-4" v-text="item.description" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '../plugins/firebase'

import GridImagePicker from '../components/ImagePickerGrid'

export default {
  name: 'RestaurantMenuView',

  components: {
    'image-picker': GridImagePicker
  },

  props: {
    menuId: {
      type: String,
      required: true
    },
    restaurantId: {
      type: String,
      required: true
    }
  },

  data () {
    return ({
      loading: false,
      item: {},
      photos: [],
      restaurantName: null,

      activePhoto: 0
    })
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    formattedPrice () {
      var o =
        this.$data.item ? Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(this.$data.item.price) : null
      return o
    },
    gridPhotos () {
      return this.$data.photos.map(photo => photo.publicUrl)
    },
    pageTitle () {
      return `${this.$data.item.name} by ${this.$data.restaurantName} - AmborJo`
    }
  },

  methods: {
    getMenuInfo () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/restaurants/${this.restaurantId}/products/${this.menuId}`).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    },

    getRestaurantName () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(`/restaurants/${this.restaurantId}`).get()
          .then(doc => {
            if (doc.exists) {
              resolve(doc.data().name)
            } else {
              resolve('')
            }
          })
          .catch(e => { reject(e) })
      })
    },

    getMenuPhotos () {
      return new Promise((resolve, reject) => {
        firebase.firestore().collection(`/restaurants/${this.restaurantId}/products/${this.menuId}/photos`).get()
          .then(querySnapshot => {
            var resolveValue = querySnapshot.docs.map(doc => Object.assign({ id: doc.id }, doc.data()))
            resolve(resolveValue)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  watch: {
    pageTitle (v) {
      document.title = v
    }
  },

  async mounted () {
    this.$data.loading = true

    try {
      this.$data.item = await this.getMenuInfo()
      this.$data.restaurantName = await this.getRestaurantName()
    } catch (e) {
      this.$store.commit('toast/setError', 'Failed to fetch info about this menu')
    }

    try {
      this.$data.photos = await this.getMenuPhotos()
    } catch (e) {
      this.$store.commit('toast/setError', 'Failed to fetch photos')
    }

    this.$data.loading = false
  }

}
</script>
