var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{
    'ma-0': !_vm.isOnPC,
    'pa-0': !_vm.isOnPC,
    'px-12': _vm.isOnPC
  },attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0 fill-height",attrs:{"justify":"top"}},[_c('v-col',{class:{
        'ma-0': !_vm.isOnPC,
        'pa-0': !_vm.isOnPC
      },attrs:{"cols":"12","md":"4"}},[(_vm.photos.length)?_c('v-img',{attrs:{"aspect-ratio":"1","src":_vm.photos[_vm.activePhoto].publicUrl},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-container',{staticClass:"ma-0 pa-0 fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)],1)]},proxy:true}],null,false,3500625667)}):_vm._e(),_c('image-picker',{attrs:{"photos":_vm.gridPhotos},model:{value:(_vm.activePhoto),callback:function ($$v) {_vm.activePhoto=$$v},expression:"activePhoto"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"display-3",domProps:{"textContent":_vm._s(_vm.item.name)}}),_c('h2',{staticClass:"text--secondary font-weight-light"},[_vm._v(" by "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":("/restaurants/" + _vm.restaurantId)}},[_vm._v(_vm._s(_vm.restaurantName))])],1),_c('p',{staticClass:"my-2"},[(_vm.item.price)?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.formattedPrice))]):_vm._e()],1),_c('p',{staticClass:"my-4",domProps:{"textContent":_vm._s(_vm.item.description)}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }